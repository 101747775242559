<template>
    <div class="text-center card">
        <v-card>
            <v-window v-model="step">
                <v-window-item :value="1">
                    <v-card-title class="pt-0 pb-2 mt-2 d-flex">
                        <v-select style="width: 40%;" color="deep-purple pointer accent-4" class="mx-2 flex-grow-0" no-data-text="Нет данных" width="50" :items="filter_order_modes" name="order_mode" v-model="filter_order_mode" label="По типу" dense hide-details> </v-select>
                        <v-spacer></v-spacer>
                        <v-text-field style="width: 40%;" color="deep-purple accent-4" append-icon="mdi-magnify" :label="$t('common.search')" outlined dense rounded single-line hide-details v-model="searchOrder"> </v-text-field>
                        
                        <v-spacer></v-spacer>
                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            v-model="selectedOrders"
                            :headers="orderHeaders"
                            :items="filteredOrders"
                            :single-select="singleSelectOrder"
                            :search="searchOrder"
                            item-key="id"
                            show-select
                            style="max-height: 65vh; min-height: 65vh;"
                            class="elevation-0  overflow-y-auto"
                            no-data-text="Нет новых заказов"
                            no-results-text="Нет такого заказа, попробуйте изменить запрос"
                            checkbox-color="deep-purple accent-4"
                            hide-default-footer
                            :footer-props="{
                                itemsPerPageOptions: [-1],
                            }"
                            @click:row="onClickSelectOrder($event)"
                        >
                            <template v-slot:[`item.name`]="{ item }">
                                <div class="d-flex">
                                    <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-chip small v-bind="attrs" v-on="on" :color="orderStatusColorHtml(item.status)" text-color="white">
                                                <v-icon size="small" class="mr-1">{{ orderStatusIcon(item.status) }}</v-icon>
                                                {{ item.name }}
                                            </v-chip>
                                        </template>
                                        <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                            <div class="order-card" v-html="orderContent(item)"></div>
                                        </v-card>
                                    </v-menu>
                                </div>
                            </template>

                            <template v-slot:[`item.date`]="{ item }"> {{ item.mode.split(' ')[0] }} {{ timeToString(item.date) }} </template>
                            <template v-slot:[`item.date_ready`]="{ item }"> {{ timeToString(item.date_ready) }} </template>
                            <template v-slot:[`item.date_delivery`]="{ item }"> {{ timeToString(item.date_delivery) }} </template>
                        </v-data-table>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                    <v-card-title class="pt-0 pb-2 d-flex">
                        <v-select class="mx-2 flex-grow-0" style="width: 40%;" color="deep-purple accent-4" no-data-text="Нет данных" :items="filter_statuses" name="order_mode" v-model="filter_status" label="По статусу" hide-details>
                            <template slot="selection" slot-scope="data">
                                <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon
                                >{{ data.item.text }}
                            </template>
                            <template slot="item" slot-scope="data">
                                <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon
                                >{{ data.item.text }}
                            </template>
                        </v-select>
                        <v-spacer></v-spacer>
                        <v-text-field style="width: 40%;" outlined dense rounded color="deep-purple accent-4" v-model="searchCourier" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            v-model="selectedCourier"
                            :headers="courierHeaders"
                            :items="filteredCouriers"
                            :single-select="singleSelectCourier"
                            :search="searchCourier"
                            item-key="tid"
                            style="max-height: 65vh; min-height: 65vh;"
                            class="elevation-0 pointer overflow-y-auto"
                            show-select
                            fixed-header
                            no-data-text="Нет подключенных курьеров"
                            no-results-text="Нет такого курьера, попробуйте изменить запрос"
                            checkbox-color="deep-purple accent-4"
                            hide-default-footer
                            :footer-props="{
                                itemsPerPageOptions: [-1],
                            }"
                            @click:row="onClickSelectCourier($event)"
                        >
                            <template v-slot:[`item.full_name`]="{ item }">
                                <v-avatar size="24" class="mr-2">
                                    <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + item.tid}`" />
                                </v-avatar>
                                {{ item.full_name.length > 30 ? item.full_name.substr(0, 30) + '...' : item.full_name }}
                            </template>
                            <template v-slot:[`item.orders`]="{ item }">
                                <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y v-for="order of item.orders" :key="order.id">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on" class="item-order d-inline-block" :style="orderStatusStyle(order)">{{ order.name }}</span>
                                    </template>

                                    <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                        <div class="order-card" v-html="orderContent(order)"></div>
                                    </v-card>
                                </v-menu>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <div class="d-flex">
                                    <v-chip small :color="courierStatusColorHtml(item.status)" text-color="white">
                                        <v-icon size="small" class="mr-1">{{ courierStatusIcon(item.status) }}</v-icon>
                                        {{ courierStatusStr(item.status) }}
                                    </v-chip>
                                </div>
                            </template>
                            <template v-slot:[`item.enable`]="{ item }">
                                <span :style="{ color: courierConnectColor(item.enable) }">{{ courierConnectStatus(item.enable) }}</span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                    <div class="h-600 d-flex text-center justify-center align-center">
                        <v-card width="900px" class="elevation-0" outlined>
                            <div>
                                <h2 class="text-h4 font-weight-light mb-2 mt-10">
                                    {{ selectedOrders.length > 1 ? 'Заказы:' : 'Заказ:' }}
                                </h2>
                                <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y v-for="order of selectedOrders" :key="order.id">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on" class="item-order d-inline-block" :style="orderStatusStyle(order)">{{ order.name }}</span>
                                    </template>

                                    <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                        <div class="order-card" v-html="orderContent(order)"></div>
                                    </v-card>
                                </v-menu>
                                <h2 class="text-h4 font-weight-light mb-2 mt-5">
                                    Курьеру:
                                </h2>
                                <div v-for="courier of selectedCourier" :key="courier.id" class="mb-10" outlined>
                                    <v-avatar size="24" class="mr-2">
                                        <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + courier.tid}`" />
                                    </v-avatar>
                                    {{ courier.full_name.length > 30 ? courier.full_name.substr(0, 30) + '...' : courier.full_name }}
                                </div>

                                <div class="mt-5">
                                    <v-btn text @click="reset()">
                                        Отмена
                                    </v-btn>
                                    <v-btn @click="sendOrder()" text color="success">
                                        Отправить
                                    </v-btn>
                                </div>
                                <div class="mb-10">
                                    <span class="text-caption grey--text">Для переоформления заказа/курьера нажмите "Отмена"</span>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </v-window-item>
            </v-window>
            <v-card-actions v-if="step < 3">
                <v-btn :disabled="step === 1" text @click="step--" class="ml-3">
                    Назад
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn color="#031E4D" dark class="mr-3" depressed @click="nextStep()">
                    Далее
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import config from '../../config'
    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'

    export default {
        data() {
            return {
                singleSelectOrder: false,
                selectedOrders: [],
                singleSelectCourier: true,
                selectedCourier: [],
                model: [],
                orderHeaders: [
                    { text: 'Название', value: 'name', sortable: false },
                    { text: 'Готов', value: 'date' },
                    { text: 'Забрать', value: 'date_ready' },
                    { text: 'Доставить', value: 'date_delivery' },
                ],
                courierHeaders: [
                    { text: 'Имя курьера', value: 'full_name' },
                    { text: 'Процесс заказов', value: 'orders' },
                ],
                filter_order_mode: 0,
                filter_order_modes: [
                    {
                        text: '< Все >',
                        value: 0,
                    },
                    {
                        text: '⏰ Предзаказ',
                        value: 1,
                    },
                    {
                        text: '🔥 Ближайший',
                        value: 2,
                    },
                ],
                filter_status: -1,
                filter_statuses: [
                    {
                        text: '< Все >',
                        value: -1,
                    },
                    {
                        text: 'Свободен',
                        value: config.user.status.free,
                        icon: 'mdi-check-bold',
                    },
                    {
                        text: 'Обрабатывает',
                        value: config.user.status.processing,
                        icon: 'mdi-timer-sand',
                    },
                    {
                        text: 'Доставляет',
                        value: config.user.status.delivering,
                        icon: 'mdi-run-fast',
                    },
                ],
                computedCourier: {
                    tid: '',
                    full_name: '',
                },
                config,
                nameOrder: '',
                nameCourier: '',
                currentItem: {},
                searchOrder: '',
                searchCourier: '',
                step: 1,
                interval: null,
                userIsPoint: false,
            }
        },
        props: {
            couriers: {
                type: Array,
                default: null,
            },
            orders: {
                type: Array,
                default: null,
            },
        },
        mixins: [DateTime, Status, Orders],
        computed: {
            filteredOrders() {
                return this.orders.filter(o => (this.filter_order_mode ? o.mode == this.filter_order_modes[this.filter_order_mode].text : true))
            },
            filteredCouriers() {
                return this.couriers.filter(c => (this.filter_status != -1 ? c.status == this.filter_status : true))
            },
        },
        methods: {
            onClickSelectOrder(item) {
                console.log(this.selectedOrders.includes(item))
                if (!this.selectedOrders.includes(item)) {
                    this.selectedOrders.push(item)
                } else {
                    this.selectedOrders = this.selectedOrders.filter(order => order.id != item.id)
                }
            },
            onClickSelectCourier(item) {
                if (!this.selectedCourier.includes(item)) {
                    if (this.selectedCourier.length >= 1) {
                        this.selectedCourier.pop()
                        this.selectedCourier.push(item)
                    } else {
                        this.selectedCourier.push(item)
                    }
                } else {
                    this.selectedCourier = this.selectedCourier.filter(courier => courier.tid != item.tid)
                }
            },
            onClickUpdate() {
                this.$emit('update')
            },

            nextStep() {
                switch (this.step) {
                    case 1:
                        if (this.selectedOrders.length > 0) {
                            this.step++
                        } else {
                            return null
                        }
                        break
                    case 2:
                        if (this.selectedCourier.length > 0) {
                            this.step++
                        } else {
                            return null
                        }
                        break
                    default:
                        return null
                }
            },
            reset() {
                this.selectedOrders = []
                this.selectedCourier = []
                this.step = 1
            },
            sendOrder() {
                this.selectedOrders.map(currentItem => {
                    currentItem.status = config.order.status.passed
                    currentItem.courier_tid = this.selectedCourier[0].tid

                    this.$store
                        .dispatch('order_set', currentItem)
                        .then(res => {
                            if (res.data.success) {
                                const user = this.$store.state.user.name
                                const userRole = this.$store.state.user.role
                                const data = {
                                    order: currentItem,
                                    user,
                                    userRole,
                                }
                                this.$store.dispatch('order_passed_notify', data).then(res => {
                                    if (res.data.success) {
                                        console.log('order passed OK')
                                    }
                                })
                                // this.$notify.show(`Заказ ${currentItem.name} назначен успешно`, { color: 'success', timeout: 10000 })
                            } else {
                                if (res.data.status == 'no order found') {
                                    this.$notify.toast(`Заказ ${currentItem.name} уже удален`, { color: 'error', timeout: 10000 })
                                }
                                console.log('order saved error')
                            }
                        })
                        .catch(err => {
                            console.log('order_set error ', err.name, err.message)
                        })
                })
                this.$emit('update')
                this.reset()
            },
        },
        mounted() {
            this.step = 1
        },
    }
</script>

<style>
    .scroll {
        overflow-y: auto;
    }
    .h-600 {
        height: 550px;
    }
    .pointer {
        cursor: pointer;
    }
</style>
