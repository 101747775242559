<template>
    <div class="logistic">
        <div class="map-wrapper">

           <manage-window @keyup.esc="closeWindow" :couriers="couriers" :orders="orders" @update="dataUpdate"></manage-window>

            <div id="map"></div>
            <MapIcons :map="map" :data="iconsData"></MapIcons>
            <v-alert type="info" class="alert elevation-3" :value="alert" :dismissible="true">
                {{ $t('logistic.alert.no_couriers_found') }}
            </v-alert>            
        </div>

        
        <div class="card-wrapper">
            
            <v-btn
              class="fab-menu"
              v-model="fab"
              color="blue darken-2"
              elevation="10"
              dark
              fab
              @click="fab = !fab"
            >
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-menu
              </v-icon>
            </v-btn>

            <v-expansion-panels v-if="fab" v-model="panel" :disabled="disabled" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-run-fast</v-icon>
                        <span class="text-no-wrap" style="color:#666">В процессе доставки - {{ couriersProcess.length }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="expansion-panel-content">
                        <p v-if="!couriersProcess.length" class="item-courier-empty">нет курьеров</p>
                        <p v-for="(courier, i) of couriersProcess" :key="courier.id" class="item-courier" v-ripple @click="onClickCourier(courier)">
                            {{ i + 1 }}. {{ courier.full_name }}
                            <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y v-for="order of courier.orders" :key="order.id">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="item-order d-inline-block" :style="orderStatusStyle(order)">{{ order.name }}</span>
                                </template>

                                <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                    <div class="order-card" v-html="orderContent(order)"></div>
                                </v-card>
                            </v-menu>
                        </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="!userIsPoint">
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-check</v-icon>
                        <span class="text-no-wrap" style="color:#666">Свободные курьеры - {{ couriersFree.length }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="expansion-panel-content">
                        <p v-if="!couriersFree.length" class="item-courier-empty">нет курьеров</p>
                        <p v-for="(courier, i) of couriersFree" :key="courier.id" class="item-courier" v-ripple @click="onClickCourier(courier)">{{ i + 1 }}. {{ courier.full_name }}</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-information-outline</v-icon>
                        <span class="text-no-wrap" style="color:#666">Статусы заказов</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-chip v-for="status of orderStatuses" :key="status.text" :color="status.color" text-color="white" class="elevation-2">
                            <v-icon size="small" class="mr-1">{{ status.icon }}</v-icon>
                            {{ status.text }}
                        </v-chip>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-message-text-outline</v-icon>
                        <span class="text-no-wrap" style="color:#666">Маркеры на карте</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row class="mx-auto">
                            <v-col cols="12" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.ordersComplete" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-check-bold</v-icon> Выполненные заказы</div>
                                    </template>
                                </v-checkbox>
                            </v-col>

                            <v-col cols="6" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.orders" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-inbox-multiple</v-icon> Заказы</div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            
                            <v-col cols="6" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.points" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-home-group</v-icon> Точки</div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            
                            <v-col cols="6" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.couriers" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-car-multiple</v-icon> Курьеры</div>
                                    </template>
                                </v-checkbox>
                            </v-col>

                            <v-col cols="6" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.arrows" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-arrow-expand</v-icon> Направление</div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>


    <!-- </v-speed-dial> -->


        </div>
    </div>
</template>

<script>
    import * as L from 'leaflet'
    // import polylineDecorator from 'leaflet-polylinedecorator'
    import 'leaflet-arrowheads'
    // import textPath from 'leaflet-textpath'
    import 'leaflet/dist/leaflet.css'
    const config = require('../../config')
    import moment from 'moment-timezone'

    moment.locale('ru')

    import MapIcons from './MapIcons'
    import ManageWindow from '../manage/ManageWindow.vue'

    // метод для копирования и слияния - полезный!
    // var obj = { a: 1 };
    // var copy = Object.assign({}, obj);

    /* leaflet icon */
    delete L.Icon.Default.prototype._getIconUrl
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    })


    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'

    export default {
        mixins: [DateTime, Status, Orders],
        data() {
            return {
                map: null,
                dates: [],
                sticky: false,
                alert: false,
                orders: [],
                points: [],
                couriers: [],
                couriersProcess: [],
                couriersFree: [],
                options: {},
                object_markers: [],
                object_markers_created: false,
                object_selected: 0,
                popup: null,
                timer: null,
                panel: [],
                disabled: false,
                marker_opened: null,
                marker_with_popup: null,
                marker_over: false,
                polylines: [],
                arrows: [],
                iconsData: [],
                iconsData1: [],
                iconsData2: [],
                

                fab: false,
                fab1: false,
                transition: 'slide-x-reverse-transition',
                d1: null,
                d2: null,
                userIsPoint: false,
                mynexParams: {},
                couriersCount: 0,
                couriersHash: 0,

            }
        },
    computed: {
      activeFab () {
        switch (this.tabs) {
          case 'one': return { class: 'purple', icon: 'account_circle' }
          case 'two': return { class: 'red', icon: 'edit' }
          case 'three': return { class: 'green', icon: 'keyboard_arrow_up' }
          default: return {}
        }
      },
    },
    watch: {
      top (val) {
        this.bottom = !val
      },
      right (val) {
        this.left = !val
      },
      bottom (val) {
        this.top = !val
      },
      left (val) {
        this.right = !val
      },
    },

        components: {
            MapIcons,
            ManageWindow,
        },

        mounted() {
            console.log('!!! logistic mounted !!!')
            this.userIsPoint = this.$store.state.user.role != config.user.role.operator
            this.dates[0] = moment().format('YYYY-MM-DD')
            this.dates[1] = moment().format('YYYY-MM-DD')
            this.d1 = this.getDate1String()
            this.d2 = this.getDate2String()

            if (this.userIsPoint) {
                this.$store
                    .dispatch('user_get', this.$store.state.user.operator_tid)
                    .then(res => {
                        if (res.data.success) {
                            this.mynexParams = {
                                uid: res.data.user.mynex_uid,
                                token: res.data.user.mynex_token,
                                series: res.data.user.mynex_series,
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                this.mynexParams = {
                    uid: this.$store.state.user.mynex_uid,
                    token: this.$store.state.user.mynex_token,
                    series: this.$store.state.user.mynex_series,
                }
            }

            this.timer = setTimeout(() => {
                this.init()
            }, 10)
            // this.MapObjectHello()
        },
        destroyed() {
            console.log('!!! logistic destroyed !!!')
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.mapObjectsMarkersDelete()
        },
        methods: {

            dataUpdate() {
                if (this.timer) {
                    clearTimeout(this.timer)
                    this.mapObjectsUpdate()
                }
            },

            onClickCourier(courier) {
                console.log('onClickCourier', courier.full_name)

                this.map.setView([courier.object.data.glat, courier.object.data.glng], 16)
            },

            avatarIcon(courier) {
                return new L.DivIcon({
                    html: this.avatarIconContent(courier),
                })
            },

            avatarIconContent(courier) {
                let html = `<div class="avatar">
                    <img class="avatar-shadow" src="${require('../../assets/images/avatar-shadow-300.png')}">
                    <img class="avatar-icon" src="${config.api.delivery + 'user_avatar?tid=' + courier.tid}">
                `
                for (let i = 0; i < courier.orders.length; i++) {
                    const order = courier.orders[i]
                    html += `<div style="right: ${-25 - i * 13}px; background-color: ${this.orderStatusColorHtml(order.status)}" class="avatar-status avatar-status-pos-${i}"></div>`
                }
                html += `
                </div>`
                return html
            },

            init() {
                let center = [43.121284, 131.901917]
                let zoom = 12
                if (this.$store.state.settings.city) {
                    if (this.$store.state.settings.city.includes('Екатеринбург')) {
                        center = [56.839104, 60.60825]
                    } else if (this.$store.state.settings.city.includes('Хабаровск')) {
                        center = [48.482369, 135.084641]
                    }
                }
                this.map = L.map('map', {
                    center,
                    zoom,
                    zoomControl: true,
                    doubleClickZoom: true,
                    attributionControl: false,
                })
                // L.tileLayer(config.map.url).addTo(this.map)
                L.tileLayer(config.map.url, {
                    // attributionControl: false,
                    // trackResize: false,
                    // tapTolerance: 5,
                    maxZoom: 20,
                    minZoom: 8,
                    retina: '@2x',
                    detectRetina: true,
                }).addTo(this.map)

                console.log('logistic init', this.map)

                // map.on('movestart', onMapMoveStart);
                // map.on('moveend', onMapMoveEnd);
                // map.on('dragend', onMapDragEnd);
                // map.on('dragstart', onMapDragStart);
                // map.on('viewreset', onMapViewReset);
                // map.on('zoomstart', onMapZoomStart);
                // map.on('zoomend', onMapZoomEnd);
                // map.on('click', onMapMouseClick);

                this.map.on('popupclose', this.onMapPopupClose)
                this.map.on('popupopen', this.onMapPopupOpen)

                this.map.on('click', e => {
                    console.log('⚡️ click map')
                    this.onMapMouseClick(e)
                })

                this.map.on('mousedown', e => {
                    console.log('⚡️ mouseup mousedown')
                })

                this.map.on('mouseup', e => {
                    console.log('⚡️ mouseup map')
                })

                this.map.on('move', e => {
                    console.log('⚡️ move map')
                })

                this.map.on('keydown', e => {
                    console.log('keydown', e.originalEvent.code)
                })

                this.markersUpdate()

            },

            markersUpdate() {
                this.$store.commit('showMarkers', this.$store.state.showMarkers)
                this.dataUpdate()
                this.pointsUpdate()
            },

            pointsUpdate() {
                this.$store
                    .dispatch('points_get')
                    .then(res => {
                        if (res.data.success) {
                            if (this.userIsPoint) {
                                this.points = res.data.points.filter(p => p.id == this.$store.state.user.point_id)
                            } else {
                                this.points = res.data.points
                            }
                            this.iconsData2 = []
                            if (this.$store.state.showMarkers.points) {
                                for (let point of this.points) {
                                    let coordinates = JSON.parse(point.coordinates)
                                    if (coordinates) {
                                        // const this_ = this
                                        this.iconsData2.push({
                                            id: point.id,
                                            color: '#233e6d',
                                            content: '🏪 ' + (point.name.length > 15 ? point.name.substr(0, 15) + '...' : point.name),
                                            lat: +coordinates[1] + (0.5 - Math.random())/5000,
                                            lon: +coordinates[0] + (0.5 - Math.random())/5000,
                                            options: {
                                                data: point,
                                            },
                                            tooltip: function () {
                                                return `
                                                🏪 <b>${ this.options.data.name }</b><br>
                                                🏡 Адрес: <b>${ this.options.data.address.replace('\n', ' ') }</b><br>
                                                
                                                `
                                            }
                                        })
                                    }
                                }
                            }
                            this.iconsData = this.iconsData1.concat(this.iconsData2)
                        }
                    })
            },

            mapObjectsUpdate() {

                console.log('tick mapObjectsUpdate')

                let point_id
                if (this.userIsPoint) {
                    point_id = this.$store.state.user.point_id
                } else {
                    point_id = 0
                }
                this.$store
                    .dispatch('couriers_orders_today_get', point_id)
                    .then(res => {
                        if (res.data.success) {
                            const user = this.$store.state.user
                            // console.log('user', user)
                    
                            this.$store.dispatch('orders_date', {
                                date1: this.d1,
                                date2: this.d2,
                                point_id,
                                deliveryTime: true
                            }).then(res => {
                                if (res.data.success) {
                                    this.orders = res.data.orders
                                    // console.log('this.orders', this.orders)
                                    
                                    this.iconsData1 = []
                                    let orders = []
                                    if (this.$store.state.showMarkers.ordersComplete && this.$store.state.showMarkers.orders) {
                                        orders = this.orders
                                    } else {
                                        if (this.$store.state.showMarkers.ordersComplete) {
                                            orders = this.orders.filter(order => order.status == config.order.status.complete)
                                        } else if (this.$store.state.showMarkers.orders) {
                                            orders = this.orders.filter(order => order.status != config.order.status.complete)
                                        }
                                    }
                                    // console.log('order all', orders)
                                    for (let order of orders) {
                                        let coordinates = JSON.parse(order.coordinates)
                                        if (coordinates) {
                                            const this_ = this
                                            let orderName = order.mode.substr(0,2) + ' '
                                            if (order.name.length > 20) {
                                                orderName += order.name.substr(0,20) + '...'
                                            } else {
                                                orderName += order.name
                                            }

                                            this.iconsData1.push({
                                                id: order.id,
                                                color: this.orderStatusColorHtml(order.status),
                                                content: orderName,
                                                lat: coordinates[1],
                                                lon: coordinates[0],
                                                options: {
                                                    data: order,
                                                },
                                                tooltip: function () {
                                                    return this_.orderFullText(this.options.data)
                                                }
                                            })
                                        } else {
                                            console.log('order without coordinates', order)
                                        }
                                    }
                                    this.iconsData = this.iconsData1.concat(this.iconsData2)

                                }
                            })

                            if (this.mynexParams.uid) {

                                this.$http
                                    .get(config.api.mynex + 'object/list', { params: this.mynexParams })
                                    .then(res1 => {
                                        if (this.userIsPoint) {
                                            this.couriers = res.data.couriers.filter(courier => courier.enable && courier.orders.length && courier.orders.find(o => o.point_id == this.$store.state.user.point_id))
                                        } else {
                                            this.couriers = res.data.couriers.filter(courier => courier.enable)
                                        }
                                        this.couriersProcess = this.couriers.filter(courier => courier.orders.length)
                                        this.couriersFree = this.couriers.filter(courier => !courier.orders.length)

                                        // console.log('this.couriers', this.couriers)

                                        let objects = []
                                        if (res1.data.response.success) {
                                            objects = res1.data.response.data
                                        }

                                        // console.log('objects', objects)

                                        for (let polyline of this.polylines) {
                                            polyline.deleteArrowheads()
                                            this.map.removeLayer(polyline)
                                        }

                                        let parking_lat = 43.113378
                                        let parking_lng = 131.85208
                                        if (this.$store.state.settings.city) {
                                            if (this.$store.state.settings.city.includes('Екатеринбург')) {
                                                parking_lat = 56.89629
                                                parking_lng = 60.24525
                                            } else if (this.$store.state.settings.city.includes('Хабаровск')) {
                                                parking_lat = 48.393594
                                                parking_lng = 134.968054
                                            }
                                        }

                                        let i = 0
                                        for (let courier of this.couriers) {
                                            let obj = {
                                                name: courier.full_name,
                                                ident: 0,
                                                oid: courier.tid,
                                                online: false,
                                                stime: 0,
                                                data: {
                                                    mqua: 0,
                                                    glat: parking_lat + i * 0.003,
                                                    glng: parking_lng + i * 0.0015,
                                                },
                                            }
                                            // ! тут все конечно нужно переделывать - маркер курьера если уже существует, то не припарковывать его на дефолтное место, иначе иногда бывают прыжки туда-сюда. нужно обновлять координаты маркера курьера, если они есть, если нет - оставлять прошлые, далее привязывать линию направления к позиции маркера
                                            for (let object of objects) {
                                                // console.log('courier', i, courier.full_name, courier.mynex_ident)
                                                if (courier.mynex_ident == object.ident) {
                                                    // console.log(i, courier.full_name, courier.mynex_ident, object.ident)
                                                    if (!object.data) {
                                                        object.data = {}
                                                    }
                                                    if (!object.data.mqua) object.data.mqua = 0
                                                    if (!object.data.glat) object.data.glat = parking_lat + (i + 1) * 0.003
                                                    if (!object.data.glng) object.data.glng = parking_lng + (i + 1) * 0.0015

                                                    obj = object
                                                    i--
                                                    break
                                                }
                                            }

                                            courier.object = obj

                                            if (this.$store.state.showMarkers.arrows) {

                                                const today = new Date()

                                                for (let order of courier.orders) {
                                                    const order_date = new Date(order.date)
                                                    if (!this.isSameDate({date: order_date, compareDate: today, unit: 'day'})) continue
                                                    if (order.status == config.order.status.complete) continue
                                                    // console.log('print order of courier', courier.full_name, order)
                                                    let latlngs = []
                                                    
                                                    latlngs.push([courier.object.data.glat, courier.object.data.glng])

                                                    if (order.status == config.order.status.way_guest || order.status == config.order.status.passed) {
                                                        const coordinates = JSON.parse(order.coordinates)
                                                        if (coordinates && coordinates[0] && coordinates[1]) {
                                                            latlngs.push([+coordinates[1], +coordinates[0]])
                                                        }
                                                    } else if (order.status == config.order.status.way_point) {
                                                        const point = this.points.find(point => point.id == order.point_id)
                                                        // console.log('point', point)

                                                        if (point) {
                                                            const coordinates = JSON.parse(point.coordinates)
                                                            if (coordinates) {
                                                                latlngs.push([+coordinates[1], +coordinates[0]])
                                                            }
                                                        }
                                                    }
                                                    // console.log('latlngs', latlngs)

                                                    let p = L.polyline(latlngs, {
                                                        color: '#444',
                                                        weight: 2,
                                                        dashArray: '10, 10', dashOffset: '0',
                                                    })
                                                    p.arrowheads({
                                                        yawn: 25,
                                                        size: '20px',
                                                        fill: true,
                                                        stroke: false,
                                                    })
                                                    this.polylines.push(p)
                                                    p.addTo(this.map)
                                                    p.getArrowheads().addTo(this.map)
                                                }
                                                // L.featureGroup(this.arrows).addTo(this.map)
                                            }
                                            i++
                                        }

                                        if (this.$store.state.showMarkers.couriers) {
                                            // const couriersHash = this.couriers.map(function(c) {

                                            //     return c

                                            // })
                                            if (this.object_markers_created && this.couriersCount != this.couriers.length) {
                                                this.object_markers_created = false
                                                this.mapObjectsMarkersDelete()
                                            }

                                            this.couriersCount = this.couriers.length

                                            if (!this.object_markers_created) {
                                                this.object_markers_created = true
                                                this.mapObjectsMarkersCreate()
                                            }
                                            // } else {
                                                this.mapObjectsMarkersUpdate(false)
                                            // }
                                        } else {
                                            if (this.object_markers_created) {
                                                this.object_markers_created = false
                                                this.mapObjectsMarkersDelete()
                                            }
                                        }

                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })
                            }
                        }
                        if (this.timer) {
                            this.timer = setTimeout(() => {
                                this.mapObjectsUpdate()
                            }, 5000)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            getDeviceType(type) {
                let res = '-'
                if (type == 7) {
                    res = 'ot2'
                } else if (type == 8) {
                    res = 'a5e'
                } else if (type == 14) {
                    res = 'tag'
                }
                return res
            },

            mapObjectsMarkersCreate() {
                let i = 0
                for (let courier of this.couriers) {
                    const object = courier.object
                    var data = 0
                    try {
                        data = object.data
                    } catch (e) {
                        data = []
                    }

                    if (data) {
                        if (!data.gspe) data.gspe = 0
                        if (!data.pext) data.pext = 0
                        if (!data.gsat) data.gsat = 0
                        let nocoord = false
                        if (!data.glat) {
                            data.glat = 43.073378
                            nocoord = true
                        }
                        if (!data.glng) {
                            data.glng = 131.98208
                            nocoord = true
                        }
                        if (!data.gcou) {
                            data.gcou = 110
                            nocoord = true
                        }

                        let icon = this.avatarIcon(courier)
                        let bat = data.bat ? data.bat.toFixed(0) : 0
                        if (object.device_type == 8) { // A5E
                            bat *= 20
                        }
                        this.object_markers[i] = L.marker([data.glat, data.glng], {
                            icon,
                            zIndexOffset: 2000,
                            oid: object.oid,
                            ident: object.ident,
                            orders: courier.orders,
                            index: i,
                            device_type: object.device_type,
                            speed: data.gspe.toFixed(0),
                            bat,
                            course: data.gcou,
                            online: object.online,
                            mqua: data.mqua,
                            sats: data.gsat,
                            popup_open: false,
                            popup_fix: false,
                            offset_orig: 2000,
                        }).addTo(this.map)

                        this.object_markers[i]
                            .bindTooltip('', {
                                permanent: true,
                                direction: 'right',
                                offset: [32, -30],
                                zIndexOffset: 1000,
                                offset_orig: 1000,
                            })
                            .addTo(this.map)

                        this.object_markers[i].on('click', this.onDeviceMarkerMouseClick)
                        this.object_markers[i].on('mouseover', this.onDeviceMarkerMouseOver)
                        this.object_markers[i].on('mouseout', this.onDeviceMarkerMouseOut)


                        let this_ = this
                        this.object_markers[i].content = function() {
                            let content

                            // console.log('this_.couriers', this_.couriers)
                            // console.log('this.options.index', this.options.index)

                            content = '<big><u>' + this_.couriers[this.options.index].full_name + '</u></big>&nbsp;&nbsp;'
                            content += '<small>'

                            if (!this.options.ident) {
                                content += '<span class="label-color-red">нет трекера</span>'
                            } else if (!this.options.online) {
                                content += '<span class="label-color-red">нет связи</span>'
                            } else {
                                content += '<span class="label-color-green">на связи</span>'
                            }

                            content += '&nbsp;&nbsp;<span class="label-color-gray">[' + this_.getDeviceType(this.options.device_type) + ']</span>' 

                            content += '</small><br>'
                            
                            content += '<small><span class="label-color-grey">Скорость: ' + this.options.speed + ' км/ч</span></small><br>'
                            if (this.options.bat >= 0) {
                                content += '<small><span class="label-color-grey">Батарея: ' + this.options.bat + '%</span></small><br>'
                            }

                            content += '<br>'

                            // console.log('try options.orders 3')
                            if (this.options.orders.length == 0) {
                                content += '✅ Свободен<br>'
                            } else {
                                content += `📦 Доставляет (${ this.options.orders.length }):<br>`
                                content += `<div class="popup-orders">`
                                for (let order of this.options.orders) {
                                    content += `<div class="popup-order-status"><div style="background-color: ${ this_.orderStatusColorHtml(order.status) }" class="item-order">${ order.name }</div></div>`
                                }
                                content += '</div>'
                            }
                            content += '<br>'

                            let t1 = moment().toDate()
                            let t2 = moment(this_.couriers[this.options.index].object.stime).toDate()
                            let dt = Math.ceil(Math.abs(t2.getTime() - t1.getTime()) / 1000)
                            let sec_str
                            if (!this.options.ident) {
                                sec_str = 'местоположение недоступно'
                            } else {
                                if (isNaN(dt)) {
                                    sec_str = 'нет данных о местоположении'
                                } else {
                                    sec_str = 'местоположение получено ' + this_.getSecondsStr(dt, false) + ' назад'
                                }
                            }
                            content += '<small><span class="label-color-grey">' + sec_str + '</span></small><br>'
                            return content
                        }
                    }
                    i++
                }
                // this.map.setView([this.objects[0].data.glat, this.objects[0].data.glng], 18);
            },

            mapObjectsMarkersDelete() {
                if (this.map) {
                    for (let object_marker of this.object_markers) {
                        if (object_marker) {
                            try {
                                this.map.removeLayer(object_marker)
                            } catch (e) {
                                console.log('ERROR mapObjectsMarkersDelete', object_marker, e.name, e.message)
                            }
                        }
                    }
                }
                this.object_markers = []
            },

            mapObjectsMarkersUpdate() {
                let object_index = -1
                if (this.object_selected) {
                    object_index = this.couriers.findIndex(courier => courier.object.oid == this.object_selected)
                }
                let i = 0
                for (let courier of this.couriers) {
                    const object = courier.object

                    let offset = 2000
                    if (i == object_index) offset = 3000
                    let data = 0
                    try {
                        data = object.data
                    } catch (e) {
                        console.log('ERROR X', e.name, e.message)
                    }

                    const object_marker = this.object_markers[i]

                    i++

                    if (!data || !data.glat || !data.glng) {
                        console.log('skip data', courier.full_name, object.oid)
                        continue
                    }

                    if (data) {
                        let marker = {}
                        try {
                            marker = object_marker
                        } catch (e) {
                            this.mapObjectsMarkersDelete()
                            this.mapObjectsMarkersCreate()
                            marker = object_marker
                        }

                        if (marker) {
                            if (!data.gspe) data.gspe = 0
                            if (!data.pext) data.pext = 0
                            if (!data.gsat) data.gsat = 0
                            if (!data.gcou) data.gcou = 0
                            {
                                if (!data.glat) {
                                    data.glat = 43.073378
                                }
                                if (!data.glng) {
                                    data.glng = 131.98208
                                }
                                if (!data.gcou) {
                                    data.gcou = 110
                                }
                            }

                            marker.options.speed = data.gspe.toFixed(0)
                            marker.options.bat = data.bat ? data.bat.toFixed(0) : 0
                            if (object.device_type == 8) { // A5E
                                marker.options.bat *= 20
                            }

                            // console.log('object', object)

                            marker.setLatLng([data.glat, data.glng])

                            if (this.marker_opened != marker) marker.setZIndexOffset(offset)
                            marker.options.pext = data.pext.toFixed(1)
                            marker.options.sats = data.gsat
                            marker.options.course = data.gcou
                            marker.options.online = object.online
                            marker.options.mqua = data.mqua
                            marker.options.offset_orig = offset
                            marker.options.stime = object.stime
                            // console.log('try options.orders 1')
                            marker.options.orders = courier.orders
                        }

                        let t1 = moment().toDate()
                        let t2 = moment(object.stime).toDate()
                        let dt = Math.ceil(Math.abs(t2.getTime() - t1.getTime()) / 1000)
                        let cl = 'status-color-green'
                        if (!object.ident) {
                            cl = 'status-color-grey'
                        } else if (!object.online || (object.ping > 0 && dt > object.ping * 2)) {
                            cl = 'status-color-red'
                        }
                        let name = `<div class="status ${cl}"></div><span class="label-name">${ courier.full_name }</span>`
                        if (marker) {
                            marker._icon.innerHTML = this.avatarIconContent(courier)
                            marker.setTooltipContent(name)
                            marker.update()
                        }
                        if (marker == this.marker_opened) {
                            this.popup.setContent(marker.content())
                            this.popup.setLatLng([data.glat, data.glng])
                            this.popup.update()
                        }
                    }
                }
            },

            onMapMouseClick(e) {
                if (e.originalEvent.target.nodeName !== 'path') {
                    if (this.marker_opened) {
                        this.popup_close(this.marker_opened, true)
                    }
                }
            },

            // События с попапами (открытие и закрытие)
            onMapPopupClose(e) {
                // Событие закрытия отрытого попапа на карте (e)
                if (!this.marker_over) {
                    if (this.marker_opened) this.popup_close(this.marker_opened, false)
                }
            },

            onMapPopupOpen(e) {
                this.marker_with_popup = this.marker_opened
            },

            popup_open(marker, autopan, offsety) {
                let option
                if (autopan) {
                    option = {
                        offset: [0, -offsety],
                        autoPanPaddingTopLeft: [50, 90],
                        autoPanPaddingBottomRight: [50, 0],
                        closeButton: false,
                        closeOnClick: false,
                    }
                } else {
                    option = {
                        offset: [0, -offsety],
                        autoPan: false,
                        closeButton: false,
                        closeOnClick: false,
                    }
                }
                this.popup = L.popup(option)
                    .setLatLng(marker.getLatLng())
                    .setContent(marker.content())
                    .openOn(this.map)

                marker.options.popup_open = true
                marker.options.popup_fix = false
                this.marker_opened = marker
                this.object_selected = marker.options.oid
                if (marker.setZIndexOffset) marker.setZIndexOffset(5000)
            },

            popup_close(marker, popup_close) {
                if (marker) {
                    // if (marker.options.micon) marker.setIcon(marker.options.micon)
                    if (popup_close) this.map.closePopup(this.popup)
                    marker.options.popup_open = false
                    marker.options.popup_fix = false
                    if (marker.setZIndexOffset) marker.setZIndexOffset(marker.options.offset_orig)
                    this.marker_opened = null
                    this.object_selected = 0
                }
            },

            popup_fix_change(marker, offsety) {
                if (marker.options.micon) {
                    offsety = marker.options.icon.options.iconAnchor[1] + 1
                    if (marker.options.rotationAngle && marker.options.rotationAngle == 180) offsety = 6
                }
                if (marker.options.popup_fix) {
                    // Попап закреплен - убираем закрепление и кнопку закрытия на попапе
                    this.popup = L.popup({
                        offset: [0, -offsety],
                        autoPan: false,
                        closeButton: false,
                        closeOnClick: false,
                    })
                        .setLatLng(marker.getLatLng())
                        .setContent(marker.content())
                        .openOn(this.map)
                    marker.options.popup_fix = false
                } else {
                    // Попап не закреплен - устанавливаем закрепление и кнопку закрытия на попапе
                    this.popup = L.popup({
                        offset: [0, -offsety],
                        autoPanPaddingTopLeft: [50, 90],
                        autoPanPaddingBottomRight: [50, 0],
                        closeButton: true,
                        closeOnClick: false,
                    })
                        .setLatLng(marker.getLatLng())
                        .setContent(marker.content())
                        .openOn(this.map)
                    marker.options.popup_fix = true
                }
            },

            // События с маркерами объектов
            onDeviceMarkerMouseClick(e) {
                // console.log('onDeviceMarkerMouseClick', e)

                if (e.target.options.oid) {
                    let index = e.target.options.index
                    let object_index = this.couriers.findIndex(courier => courier.object.oid == this.object_selected)

                    if (index != object_index) {
                        this.popup_close(this.marker_opened, true)
                    }
                    var offsety = 56
                    if (this.marker_over) {
                        if (this.marker_opened && e.target == this.marker_opened) this.popup_fix_change(e.target, offsety)
                        else {
                            this.popup_close(this.marker_opened, true)
                            this.popup_open(e.target, true, offsety)
                            this.popup_fix_change(e.target, offsety)
                        }
                    } else {
                        // Only for tap of touch
                        if (this.marker_opened && e.target == this.marker_opened) this.popup_close(this.marker_opened, true)
                        else this.popup_open(e.target, true, offsety)
                    }
                }
            },

            onDeviceMarkerMouseOver(e) {
                if (!this.marker_over) {
                    this.marker_over = true
                }
                if (e.target.options.popup_open) {
                    // Наводим на маркер с открытым попапом
                } else {
                    var offsety = 56
                    // Наводим на маркер с закрытым попапом
                    if (!this.marker_opened || this.marker_opened.options.popup_fix == false) {
                        // если не зафиксирован открытый попап - открываем попап без кнопки закрытия
                        if (this.marker_opened) this.popup_close(this.marker_opened, true)
                        this.popup_open(e.target, false, offsety)
                    }
                }
            },

            onDeviceMarkerMouseOut(e) {
                if (e.target.options.popup_open) {
                    // Убираем указатель мыши с маркера с открытым попапом
                    if (!e.target.options.popup_fix) {
                        // Попап не зафиксирован - закрываем его попап и убираем фиксацию
                        this.popup_close(e.target, true)
                    }
                    if (this.marker_over) {
                        this.marker_over = false
                    }
                }
            },
        },
    }
</script>

<style lang="stylus">

    .expansion-panel-content
        max-height 240px
        overflow-y auto
        margin-bottom 20px

    .logistic
        height 100%
        
        #map
          width 100%
          height 100%
          z-index 1

        .map-wrapper
            width 100%
            height 100%
            position absolute
            display flex

        .fab-menu
            margin-bottom 5px

        .card-wrapper
            position absolute
            top 10px
            right 20px
            max-width 420px
            display flex
            flex-direction column
            align-items flex-end
            z-index 1

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                cursor pointer
                padding 0 6px


        .status
            width 6px
            height 6px
            display inline-block
            margin-right 4px
            margin-bottom 2px

        .status-color-red
            background-color red

        .status-color-green
            background-color green

        .status-color-grey
            background-color grey

        .label-color-red
            color red

        .label-color-green
            color green

        .label-color-grey
            color grey

        .leaflet-div-icon
            border none
            background none

        .popup-orders
            display flex
            flex-direction column

        .popup-order-status
            display flex
            padding 2px 0

        .order-id-status
            display initial
            position initial
            border-radius 2px
            padding 0px 2px
            margin 0
            margin-right 6px
            color white
            font-size 12px
            font-weight 600
            box-shadow 1px 1px 1px grey
            cursor default


        .avatar-icon
            width 52px
            height 52px
            top -52px
            left -20px
            position absolute
            border-radius 50%

        .avatar-disabled
            filter brightness(300%)
            filter sepia(100%)
            // filter grayscale(100%)
            filter gray
            filter blur(0.5em)

        .avatar-shadow
            width 64px
            height 64px
            top -54px
            left -26px
            position absolute

        .avatar-status
            width 20px
            height 20px
            border-radius 50%
            top -55px
            right -25px
            position absolute
            border solid 1px white
            box-shadow 0 0 1px black

        .label-name
            font-weight bold
            font-size 14px !important

        .leaflet-tooltip
            background-color rgba(220, 220, 220,0.7)
            color #333
            box-shadow none
            margin 0 0px
            padding 0
            border none
            border-radius 2px
            font-size 12px
            padding 1px 3px
            white-space nowrap
            text-shadow 1px 1px 2px white, -1px -1px 1px white
            z-index 1
            font-weight normal

        .leaflet-tooltip-top::before, .leaflet-tooltip-bottom::before, .leaflet-tooltip-left::before, .leaflet-tooltip-right::before
            display none

        .leaflet-tooltip-pane
            z-index 550
            
        .leaflet-popup-content-wrapper
            border-radius 4px
            font-size 13px

        .leaflet-popup-content
            margin 12px 14px

        .item-courier
            color #333
            cursor pointer
            font-size 14px
            font-weight 500
            padding 5px
            margin 0
            &:hover
                background-color #f0f0f0

        .item-courier-empty
            color #aaa
            cursor default


    .arrow-icon {
        width: 18px;
        height: 18px;
        opacity 0.8
        // z-index 0
    }

    .arrow-icon > div {
        // opacity 0.6
        margin-left: -4px
        margin-top: -4px;
        transform-origin: center center;
        font: 18px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    }

</style>
