<template>
    <div>
        <div v-if="!preNew && !preComplete && !preDelete">
            <v-btn :color="orderStatusColorHtml(config.order.status.new)" dark x-small @click="preNew = true">
                Новый
            </v-btn>

            <v-btn class="ml-3" :color="orderStatusColorHtml(config.order.status.complete)" dark x-small @click="preComplete = true">
                Доставлен
            </v-btn>
            <v-btn color="error" dark x-small class="ml-3" @click="preDelete = true">Удалить</v-btn>
        </div>
        <div class="mt-2 mb-2 d-flex justify-center flex-column" v-else>
            <b>
                {{ `${order.name} ${confirm}` }}
            </b>
            <div class="ml-2">
                <v-btn text color="primary" @click="editOrder(order)">Да</v-btn>
                <v-btn text @click="cancel()">Нет</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import Orders from '../../../mixins/Orders.js'
    import Status from '../../../mixins/Status.js'
    import config from '../../../config'
    export default {
        data() {
            return {
                config,
                currentItem: {},
                preNew: false,
                preComplete: false,
                preDelete: false,
            }
        },

        props: {
            couriers: {
                type: Array,
                default: null,
            },
            order: {
                type: Object,
                default: null,
            },
        },
        mixins: [Orders, Status],

        methods: {
            notifyDeleteOrder() {
                const order = this.currentItem
                const user = this.$store.state.user.name
                const userRole = this.$store.state.user.role
                const data = {
                    order,
                    user,
                    userRole,
                }
                this.$store
                    .dispatch('order_cancel_notify', data)
                    .then(res => {
                        if (res.data.success) {
                            console.log('notify ok')
                        }
                    })
                    .catch(err => {
                        console.log('notify false', err.name, err.message)
                    })
            },
            orderNew() {
                let courier = this.couriers.find(courier => courier.tid == this.currentItem.courier_tid)
                if (courier) {
                    if (courier.act_ord) {
                        courier.act_ord--
                    }
                    if (courier.act_ord == 0) {
                        courier.status = config.user.status.free
                    }
                    this.$store.dispatch('user_set', courier).then(res => {
                        if (res.data.success) {
                            console.log('user saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })
                }
                this.notifyDeleteOrder()
                const order = JSON.parse(JSON.stringify(this.currentItem))
                order.status = config.order.status.new
                order.courier_tid = null
                order.date_take = null
                order.date_complete = null

                this.$store
                    .dispatch('order_set', order)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                        this.$emit('update')
                    })
                    .catch(err => {
                        console.log('order_set error', err.name, err.message)
                    })
                this.$store.state.newOrdersCount = this.$store.state.newOrdersCount++
            },
            orderComplete() {
                let courier = this.couriers.find(courier => courier.tid == this.currentItem.courier_tid)

                if (courier) {
                    if (courier.act_ord) {
                        courier.act_ord--
                    }
                    if (courier.act_ord == 0) {
                        courier.status = config.user.status.free
                    }

                    this.$store.dispatch('user_set', courier).then(res => {
                        if (res.data.success) {
                            console.log('user saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })
                }

                const order = JSON.parse(JSON.stringify(this.currentItem))
                order.status = config.order.status.complete
                order.date_take = this.currentItem.date_ready
                order.date_complete = this.currentItem.date_delivery

                this.$store
                    .dispatch('order_set', order)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order saved ok')
                            this.notifyDeleteOrder()
                        } else {
                            console.log('order saved fail')
                        }
                        this.dialogStatus = false
                        this.$emit('update')
                    })
                    .catch(err => {
                        console.log('order_set error', err.name, err.message)
                        this.dialogStatus = false
                    })
            },
            deleteOrder() {
                const order = this.currentItem
                order.status_delete = config.order.status.deleted
                console.log(order)
                this.$store
                    .dispatch('order_delete', order.id)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order delete ok')
                        } else {
                            console.log('order delete fail')
                        }
                        this.$emit('update')
                    })
                    .catch(err => {
                        console.log('order_delete error', err.name, err.message)
                    })
                this.cancel()
            },
            editOrder(item) {
                this.currentItem = item
                if (this.preNew) {
                    this.orderNew()
                } else if (this.preComplete) {
                    this.orderComplete()
                } else if (this.preDelete) {
                    this.deleteOrder()
                }
            },
            cancel() {
                this.preNew = false
                this.preComplete = false
                this.preDelete = false
            },
        },

        computed: {
            confirm() {
                let str = ''
                if (this.preNew) {
                    str = `отменить и сделать как "Новый"?`
                } else if (this.preComplete) {
                    str = `отменить и сделать "Доставлен"?`
                } else if (this.preDelete) {
                    str = `действительно удалить?`
                }
                return str
            },
        },
    }
</script>
