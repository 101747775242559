<template>
    <div>
        <div v-if="!preDelete">
            <v-btn color="error" x-small dark @click="preDelete = true">
                Отключить
            </v-btn>
        </div>
        <div class="mt-2 mb-2 d-flex justify-center flex-column" v-else>
            <b>
                {{ `${confirm} ${courier.full_name}?` }}
            </b>
            <div v-if="courier.orders.length">
                <div>Заказы, которые доставляет курьер сделать как:</div>
                <div class="d-flex justify-center mb-2">
                    <v-radio-group row v-model="orders_status" mandatory hide-details>
                        <v-radio label="Новые" :color="orderStatusColorHtml(config.order.status.new)" :value="config.order.status.new"></v-radio>
                        <v-radio label="Доставлены" :color="orderStatusColorHtml(config.order.status.complete)" :value="config.order.status.complete"></v-radio>
                    </v-radio-group>
                </div>
            </div>
            <v-divider class="mx-4"></v-divider>
            <div class="ml-2">
                <v-btn text color="primary" @click="disableCourier(courier)">Отключить</v-btn>
                <v-btn text @click="cancel()">Отмена</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import config from '../../../config'
    import Orders from '../../../mixins/Orders.js'
    import Status from '../../../mixins/Status.js'

    export default {
        data() {
            return {
                config,
                currentItem: {},
                orders_status: 0,
                preDelete: false,
            }
        },
        props: {
            courier: {
                type: Object,
                default: null,
            },
        },
        mixins: [Orders, Status],
        methods: {
            notifyCourierStatus() {
                const data = {
                    courierName: this.currentItem.full_name,
                    courierTid: this.currentItem.tid,
                    operatorTid: this.currentItem.operator_tid,
                }
                this.$store
                    .dispatch('courier_status_notify', data)
                    .then(res => {
                        if (res.data.success) {
                            console.log('notify ok')
                        }
                    })
                    .catch(err => {
                        console.log('notify false', err.name, err.message)
                    })
            },
            disableCourier(item) {
                console.log('disableCourier', item)
                let courier = JSON.parse(JSON.stringify(item))
                if (courier.orders.length) {
                    this.$store
                        .dispatch('courier_orders_status', { courier_tid: courier.tid, status: this.orders_status })
                        .then(res => {
                            if (res.data.success) {
                                console.log('order saved ok')
                            } else {
                                console.log('order saved fail')
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
                courier.act_ord = 0
                courier.status = config.user.status.free
                courier.enable = 0

                this.$store
                    .dispatch('user_set', courier)
                    .then(res => {
                        if (res.data.success) {
                            console.log('user saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                        this.$emit('update')
                    })
                    .catch(err => {
                        console.log(err)
                    })

                this.notifyCourierStatus()
            },
            cancel() {
                this.preDelete = false
            },
        },
        computed: {
            confirm() {
                return `Действительно отключить курьера `
            },
        },
    }
</script>
