var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-card',[_c('v-card-title',{staticClass:"pt-0 pb-2 d-flex"},[_c('v-select',{staticClass:"mx-2 flex-grow-0",staticStyle:{"width":"40%"},attrs:{"color":"deep-purple accent-4","no-data-text":"Нет данных","items":_vm.filter_statuses,"name":"order_mode","label":"По статусу","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(data.item.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"#444"}},[_vm._v(_vm._s(data.item.icon))]):_vm._e(),_vm._v(_vm._s(data.item.text)+" ")]}},{key:"item",fn:function(data){return [(data.item.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"#444"}},[_vm._v(_vm._s(data.item.icon))]):_vm._e(),_vm._v(_vm._s(data.item.text)+" ")]}}]),model:{value:(_vm.filter_status),callback:function ($$v) {_vm.filter_status=$$v},expression:"filter_status"}}),_c('v-spacer'),_c('v-text-field',{staticStyle:{"width":"40%"},attrs:{"color":"deep-purple accent-4","append-icon":"mdi-magnify","label":_vm.$t('common.search'),"single-line":"","hide-details":"","outlined":"","dense":"","rounded":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.dataUpdate}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0 overflow-y-auto",staticStyle:{"max-height":"70vh","min-height":"70vh","cursor":"pointer"},attrs:{"headers":_vm.courierHeaders,"items":_vm.filteredCouriers,"search":_vm.search,"item-key":"tid","fixed-header":"","show-expand":"","no-data-text":"Нет подключенных курьеров","no-results-text":"Нет такого курьера, попробуйте изменить запрос","hide-default-footer":"","footer-props":{
                    itemsPerPageOptions: [-1],
                }},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
                var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"24"}},[_c('img',{attrs:{"alt":"courier","src":("" + (_vm.config.api.delivery + 'user_avatar?tid=' + item.tid))}})]),_vm._v(" "+_vm._s(item.full_name.length > 30 ? item.full_name.substr(0, 30) + '...' : item.full_name)+" ")]}},{key:"item.orders",fn:function(ref){
                var item = ref.item;
return _vm._l((item.orders),function(order){return _c('v-menu',{key:order.id,attrs:{"open-delay":"750","open-on-hover":"","close-on-content-click":false,"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"item-order d-inline-block",style:(_vm.orderStatusStyle(order))},'span',attrs,false),on),[_vm._v(_vm._s(order.name))])]}}],null,true)},[_c('v-card',{staticClass:"mx-auto",attrs:{"min-width":"120","max-width":"400","outlined":""}},[_c('div',{staticClass:"order-card",domProps:{"innerHTML":_vm._s(_vm.orderContent(order))}})])],1)})}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-chip',{attrs:{"small":"","color":_vm.courierStatusColorHtml(item.status),"text-color":"white"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"small"}},[_vm._v(_vm._s(_vm.courierStatusIcon(item.status)))]),_vm._v(" "+_vm._s(_vm.courierStatusStr(item.status))+" ")],1)],1)]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('courier-row-action',{attrs:{"courier":item},on:{"update":_vm.dataUpdate}})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }