<template>
    <div class="text-center">
        <v-card>
            <v-card-title class="pt-0 pb-2 d-flex">
                <v-select style="width: 40%;" class="mx-2 flex-grow-0" color="deep-purple accent-4" no-data-text="Нет данных" :items="filter_statuses" name="order_mode" v-model="filter_status" label="По статусу" hide-details>
                    <template slot="selection" slot-scope="data">
                        <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon
                        >{{ data.item.text }}
                    </template>
                    <template slot="item" slot-scope="data">
                        <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon
                        >{{ data.item.text }}
                    </template>
                </v-select>
                <v-spacer></v-spacer>
                <v-text-field style="width: 40%;" color="deep-purple accent-4" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details outlined dense rounded></v-text-field>
                <v-spacer></v-spacer>
                <v-btn icon @click="dataUpdate">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    style="max-height: 70vh; min-height: 70vh; cursor: pointer;"
                    :headers="courierHeaders"
                    :items="filteredCouriers"
                    :search="search"
                    item-key="tid"
                    fixed-header
                    show-expand
                    class="elevation-0 overflow-y-auto"
                    no-data-text="Нет подключенных курьеров"
                    no-results-text="Нет такого курьера, попробуйте изменить запрос"
                    hide-default-footer
                    :footer-props="{
                        itemsPerPageOptions: [-1],
                    }"
                    @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                >
                    <template v-slot:[`item.full_name`]="{ item }">
                        <v-avatar size="24" class="mr-2">
                            <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + item.tid}`" />
                        </v-avatar>
                        {{ item.full_name.length > 30 ? item.full_name.substr(0, 30) + '...' : item.full_name }}
                    </template>
                    <template v-slot:[`item.orders`]="{ item }">
                        <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y v-for="order of item.orders" :key="order.id">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="item-order d-inline-block" :style="orderStatusStyle(order)">{{ order.name }}</span>
                            </template>

                            <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                <div class="order-card" v-html="orderContent(order)"></div>
                            </v-card>
                        </v-menu>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <div class="d-flex">
                            <v-chip small :color="courierStatusColorHtml(item.status)" text-color="white">
                                <v-icon size="small" class="mr-1">{{ courierStatusIcon(item.status) }}</v-icon>
                                {{ courierStatusStr(item.status) }}
                            </v-chip>
                        </div>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <courier-row-action :courier="item" @update="dataUpdate"></courier-row-action>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import config from '../../config'
    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'
    import CourierRowAction from './row_action/CourierRowAction.vue'

    export default {
        data() {
            return {
                courierHeaders: [
                    { text: 'Имя курьера', value: 'full_name', sortable: false },
                    { text: 'Процесс заказов', value: 'orders', sortable: false },
                    { text: 'Действия', value: 'data-table-expand', sortable: false },
                ],
                filter_status: -1,
                filter_statuses: [
                    {
                        text: '< Все >',
                        value: -1,
                    },
                    {
                        text: 'Свободен',
                        value: config.user.status.free,
                        icon: 'mdi-check-bold',
                    },
                    {
                        text: 'Обрабатывает',
                        value: config.user.status.processing,
                        icon: 'mdi-timer-sand',
                    },
                    {
                        text: 'Доставляет',
                        value: config.user.status.delivering,
                        icon: 'mdi-run-fast',
                    },
                ],
                config,
                search: '',
                orders_status: 0,
                currentItem: {},
            }
        },
        mixins: [DateTime, Status, Orders],
        computed: {
            filteredCouriers() {
                return this.couriers.filter(c => (this.filter_status != -1 ? c.status == this.filter_status : true))
            },
        },
        components: {
            CourierRowAction,
        },
        props: {
            couriers: {
                type: Array,
                default: null,
            },
            orders: {
                type: Array,
                default: null,
            },
        },
        methods: {
            dataUpdate() {
                this.$emit('update')
            },
        },
    }
</script>
