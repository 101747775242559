var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"pt-0 pb-2 d-flex"},[_c('v-select',{staticClass:"mx-2 flex-grow-0",staticStyle:{"width":"40%"},attrs:{"color":"deep-purple accent-4","no-data-text":"Нет данных","items":_vm.filter_order_modes,"name":"order_mode","label":"По типу","hide-details":""},model:{value:(_vm.filter_order_mode),callback:function ($$v) {_vm.filter_order_mode=$$v},expression:"filter_order_mode"}}),_c('v-spacer'),_c('v-text-field',{staticStyle:{"width":"40%"},attrs:{"color":"deep-purple accent-4","append-icon":"mdi-magnify","label":_vm.$t('common.search'),"outlined":"","dense":"","rounded":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.dataUpdate}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0 overflow-y-auto",staticStyle:{"max-height":"70vh","min-height":"70vh","cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.filteredOrders,"search":_vm.search,"single-expand":_vm.singleExpand,"show-expand":"","item-key":"name","fixed-header":"","no-data-text":"Нет активных заказов","no-results-text":"Нет такого заказа, попробуйте изменить запрос","checkbox-color":"deep-purple accent-4","hide-default-footer":"","footer-props":{
                    itemsPerPageOptions: [-1],
                }},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-menu',{attrs:{"open-delay":"750","open-on-hover":"","close-on-content-click":false,"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":_vm.orderStatusColorHtml(item.status),"text-color":"white"}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"small"}},[_vm._v(_vm._s(_vm.orderStatusIcon(item.status)))]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}}],null,true)},[_c('v-card',{staticClass:"mx-auto",attrs:{"min-width":"120","max-width":"400","outlined":""}},[_c('div',{staticClass:"order-card",domProps:{"innerHTML":_vm._s(_vm.orderContent(item))}})])],1)],1)]}},{key:"item.date",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.mode.split(' ')[0])+" "+_vm._s(_vm.timeToString(item.date))+" ")]}},{key:"item.date_ready",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeToString(item.date_ready))+" ")]}},{key:"item.date_delivery",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeToString(item.date_delivery))+" ")]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.status == _vm.config.order.status.way_point || item.status == _vm.config.order.status.way_guest || item.status == _vm.config.order.status.passed || item.status == _vm.config.order.status.deleted)?_c('div',[_c('order-row-action',{attrs:{"order":item,"couriers":_vm.couriers},on:{"update":_vm.dataUpdate}})],1):_vm._e()])]}}],null,true)})],1),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }