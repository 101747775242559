<template>
    <div v-if="this.$store.state.isLogistic" v-bind:class="{ close: this.$store.state.isOrderWindowClose }" id="drag" @click="onClick($event)">
        <vue-draggable-resizable class-name="block" :prevent-deactivation="true" :active="true" :draggable="false" :min-width="450"  :w="width" @resizing="resize" :handles="['mr']" >
            <v-system-bar height="25" color="#031E4D" window dark>
                <v-btn icon @click="closeWindow">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-icon>mdi-inbox-multiple</v-icon>
                <span>Новых заказов: {{ newOrdersCount }}</span>
                <v-icon class="ml-3">mdi-car-multiple</v-icon>
                <span>Курьеров: {{ freeCouriersCount }}</span>
                <v-spacer></v-spacer>
            </v-system-bar>

            <v-tabs color="#031E4D" grow>
                <v-tabs-slider color="white"></v-tabs-slider>
                
                <v-tab>Отправить</v-tab>
                <v-tab>Отменить</v-tab>
                <v-tab>Отключить</v-tab>

                <v-tab-item>
                    <v-card flat>
                        <set-order :couriers="couriers" :orders="newOrders" @update="dataUpdate"></set-order>
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card flat>
                        <cancel-order :couriers="couriers" :orders="notNewOrders" @update="dataUpdate"></cancel-order>
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card flat>
                        <disable-courier :couriers="couriers" :orders="notNewOrders" @update="dataUpdate"></disable-courier>
                    </v-card>
                </v-tab-item>
            </v-tabs>

        </vue-draggable-resizable>
    </div>
</template>

<script>
    import config from '../../config'
    import VueDraggableResizable from 'vue-draggable-resizable'
    import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
    import SetOrder from './SetOrder.vue'
    import CancelOrder from './CancelOrder.vue'
    import DisableCourier from './DisableCourier.vue'

    export default {
        data() {
            return {
                width: this.$store.state.width,
            }
        },
        props: {
            couriers: {
                type: Array,
                default: null,
            },
            orders: {
                type: Array,
                default: null,
            },
        },
        methods: {
            dataUpdate() {
                this.$emit('update')
            },
            onClick(event) {
                // console.log('CLICK', event.target)
            },
            closeWindow() {
                this.$store.state.isOrderWindowClose = true
            },
            resize(left, top, width, heigh) {
                console.log('resize:', width)
                this.$store.commit('window_width', width)
                console.log('resize store: ', this.$store.state.width)
            },
        },
        computed: {
            newOrdersCount() {
                return this.orders.filter(o => o.status == 0).length
            },
            newOrders() {
                return this.orders.filter(o => o.status == 0)
            },
            notNewOrders() {
                return this.orders.filter(o => o.status == config.order.status.passed || o.status == config.order.status.way_point || o.status == config.order.status.way_guest)
            },
            freeCouriersCount() {
                return this.couriers.length
            },
        },
        components: {
            VueDraggableResizable,
            SetOrder,
            CancelOrder,
            DisableCourier,
        },
    }
</script>

<style lang="stylus">
    #drag
        z-index 5
    .size
        background-color white
        height 100%
        overflow hidden
    .close
        display: none
    .block
        border none
    .handle-mr
         border-radius 10px
         margin-top 20vh
         padding 15px
         height 30vh
         background #031E4D !important 
</style>
